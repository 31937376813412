import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const Roadmap = () => {
    // Function will execute on click of button
    const onButtonClick = () => {
        // using Java Script method to get PDF file
        fetch('alephshib-whitepaper.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'alephshib-whitepaper.pdf';
                alink.click();
            })
        })
    }

    return (
        <div id='roadmap' className='about-section dark_bg' >
            <Container data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
                <div className='text-center'>
                    <h3 className='section-title text-white'>Roadmap</h3>
                </div>
                <Row>
                    <Col md={4} className='mb-4'>
                        <div className='section-inner section-card' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                            <div className='w-100'>
                                <h4 className='text-white'>Phase 1</h4>
                                <ul type='disc'>
                                    <li className='text-white'>Create social media</li>
                                    <li className='text-white'>Website Launch</li>
                                    <li className='text-white'>Token launch</li>
                                    <li className='text-white'>LP Lock</li>
                                    <li className='text-white'>500 Holders</li>
                                    <li className='text-white'>Start marketing campaigns</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} className='mb-4'>
                        <div className='section-inner section-card' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                            <div className='w-100'>
                                <h4 className='text-white'>Phase 2</h4>
                                <ul type='circle'>
                                    <li className='text-white'>1000 Holders</li>
                                    <li className='text-white'>CoinGecko & CoinMarketCap</li>
                                    <li className='text-white'>DealDex NFT Mint</li>
                                    <li className='text-white'>App release</li>
                                    <li className='text-white'>5000 Holders</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} className='mb-4'>
                        <div className='section-inner section-card' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                            <div className='w-100'>
                                <h4 className='text-white'>Phase 3</h4>
                                <ul type='circle'>
                                    <li className='text-white'>DealFlow Opportunities</li>
                                    <li className='text-white'>Staking/LP rewards activation</li>
                                    <li className='text-white'>Partnerships</li>
                                    <li className='text-white'>Cex Listings</li>
                                    <li className='text-white'>More to Come...</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default Roadmap
