import React from 'react'
import { Container } from 'react-bootstrap'

const StakingRewards = () => {
    return (
        <div id='rewards' className='about-section text-center dark_bg' data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
            <Container>
                <div className='content-section'>
                    <h2 className='section-title text-white'>Staking Rewards</h2>
                    <p className='text-white'>DealDex Token holders are able to stake their tokens to receive rewards.</p>
                    <p className='text-white'>While we are offering Deal Flow opportunity to our holders, we are the DealDex team will also participate with the collected income from taxes and arbitrage trading on Centralized exchanges, 70% of the profits made will be shared amongst DealDex Staker's.</p>
                </div>
            </Container>
        </div>
    )
}

export default StakingRewards
