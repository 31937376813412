import React from 'react'
import Navigation from './Navigation'
import { Container, Row, Col } from 'react-bootstrap'

const Hero = () => {
    return (
        <div className='hero-section'>
            <Navigation />
            <Container>
                <Row className="justify-content-center align-items-center hero-row"> {/* Custom class for vertical alignment */}
                    <Col md={8} className="text-center">
                        <div className="hero-inner" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                            <h1 className="hero-title mb-3">DealDex</h1> {/* Adjust margin as needed */}
                            <p className="hero-text mb-4">DealDex will set new standards of how retail investors participate in Deal Flow.</p>
                            <div className='d-flex justify-content-center'> {/* Flex container for horizontal button alignment */}
                                <a href='https://soon' target='_blank' rel="noreferrer" className="buy-now-btn hvr-back-pulse me-3">
                                    Buy DealDex
                                </a>
                                <a href='https://soon' target='_blank' rel="noreferrer" className="buy-now-btn hvr-back-pulse">
                                    View Chart
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Hero
