import React from 'react'
import { Container } from 'react-bootstrap'

const About = () => {
    return (
        <div id='about' className='about-section text-center' data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
            <Container>
                <div className='content-section'>
                    <h2 className='section-title'>Mission Statement</h2>
                    <p>DealDex provides a easy to use dashboard to let the average joe participate in high quality and vetted Deal flow for crypto project in early stages.</p>
                    <p>By leveraging our network of our founding members, we are able to bring the high quality and vetted Deal flow.</p>
                </div>
            </Container>
        </div>
    )
}

export default About
